import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CV = () => (
  <Layout slugLine="hello@colemancollins.com" cv="true">
    <SEO title="Coleman Collins" />
    <section className="article">
  <div className="cv-content">

    <h2 className="cv-header">Education</h2>
    <p className="cv-paragraph"><strong>University Of Notre Dame,</strong> May 2011
    <br/><strong>B.A.,</strong> College of Arts and Letters  
    <br/><strong>Majors:</strong> Graphic Design, Film Theory (Honors)
    <br/><strong>Capstone:</strong> Transmedia, Adaptation, and Intertexuality</p>

    <h2 className="cv-header">Experience</h2>

    <p className="cv-paragraph"><strong>IonQ</strong><span className="cv-duration">March 2018 – now</span>
    <span className="cv-title">Designer</span>
    <span className="cv-details">
      <span className="cv-detail -nb">Product design, strategy, and front-end engineering for quantum computing cloud (both sides of the marketplace).</span>
      <span className="cv-detail -nb">Product management, design, strategy, and front-end engineering for quantum computer operating system.</span>
      <span className="cv-detail -nb">Strategy and execution (design, frontend, content) of the IonQ brand and website.</span>
    </span>
    </p>

    <p className="cv-paragraph"><strong>Ohmcoach</strong><span className="cv-duration">October 2016 – February 2018</span>
    <span className="cv-title">Co-founder, Head of Product, Head Coach</span>
    <span className="cv-details">
      <span className="cv-detail -nb">Founded a health and fitness company that helps busy professionals achieve their full potential.</span>
      <span className="cv-detail -nb">Solo design and development of a custom software platform (Rails) to allow athletes to receive daily workouts on their phones (mobile web and SMS).</span>
      <span className="cv-detail -nb">Developed holistic health programs for a variety of busy professionals as an NSCA-Certified Strength &amp; Conditioning Specialist.</span>
      <span className="cv-detail -nb">Developed websites and content marketing material for Ohmcoach, including what became <a href="https://roadwarrior.blog">The Road Warrior</a> properties.</span>
      <span className="cv-detail -nb">Sunsetted the service at the end of 2018, pivoting the business to an infoproduct-only side project (The Road Warrior).</span>{/* Todo: case study, link as "for reasons you can read about here"*/}
    </span>
    </p>

    <p className="cv-paragraph"><strong>&amp;c.</strong><span className="cv-duration">May 2011 – now</span>
    <span className="cv-title">Partner, Product Designer</span>
    <span className="cv-details">
      <span className="cv-detail -nb">Consultant and practitioner in user research, graphic design, product design, and web development for clients in a variety of domains and media.</span>
      <span className="cv-detail -nb">Collaborated with partner to acquire clients, consult on strategic marketing, content, and consumer engagement strategies, negotiate and execute contracts.</span>

    </span>
    </p>

    <p className="cv-paragraph"><strong>ThoughtWorks</strong><span className="cv-duration">May 2014 – October 2016</span>
    <span className="cv-title">Competency Lead, Visual Design</span>
    <span className="cv-details">
      <span className="cv-detail -nb">Set the direction and expectations (as well as provided mentorship) for the professional growth, learning and development of ThoughtWorks North America's 25 designers.</span>
      <span className="cv-detail -nb">Engineered team culture, drove the continued evolution of our tools and processes, especially how we worked with development teams.</span>

    </span>
    </p>

    <p className="cv-paragraph"><strong>ThoughtWorks</strong><span className="cv-duration">June 2012 – October 2016</span>
    <span className="cv-title">Lead UX &amp; Product Design Consultant</span>
    <span className="cv-details">
      <span className="cv-detail -nb">Practitioner and consultant in product design and user experience to clients representing a variety of verticals and sizes.</span>
      <span className="cv-detail -nb">Roles, responsibilities, and outcomes varied from project to project but included:</span>
      <span className="cv-detail">Product Design — Led teams or acted as a solo designer in a wide variety of domains, including banking, ecommerce, CPG, grocery, pharmacy, developer tools, and more.</span>
      <span className="cv-detail">Front-End Development — a wide variety of prototyping and production code, including making the customer portal of a $9B line of business fully responsive and accessible.</span>
      <span className="cv-detail">Service Design — including traffic flow and throughput optimization for mobile-to-in-store ordering, payment, and pick up in a high-throughput food court.</span>
      <span className="cv-detail">User Research — everything from ethnographic studies on aircraft maintenance to full one-way-mirror usability testing of applications to digging deep into website analytics and conversion data.</span>
      <span className="cv-detail">Facilitation — in addition to the standard "Agile" ceremonies, facilitated new product innovation and project discovery workshops with a variety of clients.</span>
      <span className="cv-detail">Leadership — led design and design/dev teams as large as 8 people to deliver high-quality, low-defect products on schedule. </span>
      <span className="cv-detail">Process Consulting — worked with several large in-house dev shops to streamline handoff and collaboration between biz, design and development, in some cases bringing cycle time down from months to days.</span>
    </span>
    </p>

    <p className="cv-paragraph"><strong>Spongecell</strong><span className="cv-duration">Feb 2012 – May 2012</span>
    <span className="cv-title">Marketing Designer</span>
    <span className="cv-details">
      <span className="cv-detail -nb">Designed interactive web ads on Spongecell's proprietary interactive platform as brand and campaign extensions.</span>
      <span className="cv-detail -nb">These high-interactivity ads and dynamic creative increased click-through and conversion by up to 50%.</span>
    </span>
    </p>

    <p className="cv-paragraph"><strong>Donna Karan's Urban Zen</strong><span className="cv-duration">Jan 2012 – April 2012</span>
    <span className="cv-title">Designer</span>
    <span className="cv-details">
      <span className="cv-detail -nb">Produced print and web creative for Urban Zen Retail and The Urban Zen Foundation</span>
      <span className="cv-detail -nb">Drove a collaborative, comprehensive internal strategy for a full overhaul of the Urban Zen Foundation’s digital presence.</span>
      <span className="cv-detail -nb">This included: UX research and design, information architecture, content creation and social media strategy, paid and earned traffic generation strategy, traffic flow analytics and optimization, creating initial project timelines and plan of action, and the actual visual design of the site.</span>
    </span>
    </p>

    <p className="cv-paragraph"><strong>Web Networks, Inc.</strong><span className="cv-duration">Aug 2011 – Dec 2011</span>
    <span className="cv-title">Designer, Conversion Path Optimizer</span>
    <span className="cv-details">
      <span className="cv-detail -nb">Created landing pages, short videos, graphics for traffic funnels in the Web Networks portfolio, shepherding from concept through execution in video, code, and image.</span>
      <span className="cv-detail -nb">Optimized messaging and design using A/B and multivariate testing, traffic flow and user path analytics, and other tools.</span>
      <span className="cv-detail -nb">Improved click-through on Web Networks' main funnel by 38% in five months, resulting in a net revenue increase of $280K.</span>
    </span>
    </p>

    <h2 className="cv-header">Skills &amp; Tools</h2>
    <p className="cv-paragraph"><strong>Technical</strong>
    <span className="cv-title"></span>
    <span className="cv-details">
      <span className="cv-detail">Semantic, accessible, SEO-friendly markup</span>
      <span className="cv-detail">Deep, weird CSS/SASS knowledge</span>
      <span className="cv-detail">JavaScript, including ES2018+ syntax/methods/patterns, Typescript, React, Gatsby, node.js, jQuery, express, jest</span>
      <span className="cv-detail">Ruby + Rails</span>
    </span>
    </p>

    <p className="cv-paragraph"><strong>Design</strong>
    <span className="cv-title"></span>
    <span className="cv-details">
      <span className="cv-detail">Visual design</span>
      <span className="cv-detail">Ethnographic research (user interviews, contextual inquiry)</span>
      <span className="cv-detail">Quantitative research (heat maps, analytics)</span>
      <span className="cv-detail">User testing (scripted testing, card sorting, etc)</span>
      <span className="cv-detail">Ecommerce data analysis (Revenue, CTR, AOV, CAC, etc.)</span>
      <span className="cv-detail">Workshop design and collaborative faciliation</span>
      <span className="cv-detail">Sketching and charette exercises</span>
      <span className="cv-detail">Journey mapping</span>
      <span className="cv-detail">Wireframing &amp; information architecture</span>
      <span className="cv-detail">Prototyping (from hi-fi to paper)</span>
    </span>
    </p>

    <p className="cv-paragraph"><strong>Etc.</strong>
    <span className="cv-title"></span>
    <span className="cv-details">
      <span className="cv-detail">Agile/Scrum/XP/etc practices and processes, especially around design</span>
      <span className="cv-detail">Feature discovery and planning</span>
      <span className="cv-detail">Product roadmapping and strategy</span>
      <span className="cv-detail">Exceptional written communication skills</span>
      <span className="cv-detail">Generative text and digital/interactive art</span>
    </span>
    </p>

    <h2 className="cv-header">Public Work</h2>
      <p className="cv-paragraph"><strong>Designing For Things You Don’t Understand And People Who Are Smarter Than You</strong>
      <span className="cv-title">DCUX 2019</span>
      <span className="cv-details">
        <span className="cv-detail -nb">This <strong>talk</strong> discusses strategies for working with hyper-expert users in a deeply technical domain. Not recorded.<a href="http://dcux.org/schedule/designing-for-things-you-dont-understand-and-people-who-are-smarter-than-you/">but a synopsis is here</a>.</span>
      </span>
      </p>

      <p className="cv-paragraph"><strong>I won't tell if you won't</strong>
      <span className="cv-title">Typeforce 8 (2017)</span>
      <span className="cv-details">
        <span className="cv-detail -nb">This <strong>art installation</strong> explores technology-mediated interaction through the sharing and exposing of secrets. <a href="http://studiomosaic.org/blog/typeforce-8">Photos of the show here</a>.</span>
      </span>
      </p>

      <p className="cv-paragraph"><strong>A Cargo Cult Of Innovation</strong>
      <span className="cv-title">Business Agility Atlanta (2017)</span>
      <span className="cv-details">
        <span className="cv-detail -nb">This <strong>talk</strong> explores the difference between looking innovative and acting innovative. <a href="https://www.slideshare.net/rjpilkenton/business-agility-a-cargo-cult-of-innovation">Slides here</a>.</span>
      </span>
      </p>

      <p className="cv-paragraph"><strong>Complimentron</strong>
      <span className="cv-title">Typeforce 7 (2016)</span>
      <span className="cv-details">
        <span className="cv-detail -nb">This <strong>art installation</strong> is a robot that gives you compliments. Not everything needs deep meaning. <a href="https://github.com/colemancollins/complimentron">More here</a>.</span>
      </span>
      </p>

      <p className="cv-paragraph"><strong>Get Over Yourself: How Humility and Empathy Can Save Design</strong>
      <span className="cv-title">Interaction '15 (2015)</span>
      <span className="cv-details">
        <span className="cv-detail -nb">This <strong>panel discussion</strong> addressed the ways in which the contemporary role of product designer is as much facilitator and editor as creator. It was not recorded.</span>
      </span>
      </p>

      <p className="cv-paragraph"><strong>Designing The Second Interface</strong>
        <span className="cv-title">Qconf SF (2014)</span>
        <span className="cv-details">
          <span className="cv-detail -nb">This <strong>talk</strong> addressed the usability principles behind the invisible niceties of IDEs and text editors (like syntax highlighting). <a href="https://www.youtube.com/watch?v=6_b-_9Ti7gk">Video here</a>.</span>
        </span>
      </p>

      <p className="cv-paragraph"><strong>The Modern Web Is Broken For People With Disabilities</strong>
      <span className="cv-title">Thoughtworks Insights (2014)</span>
      <span className="cv-details">
        <span className="cv-detail -nb">This <strong>article</strong> addresses the relative incompatibility of SPAs and Screen readers. <a href="https://www.thoughtworks.com/insights/blog/modern-web-fundamentally-broken-people-disabilities">You can read it on ThoughtWorks Insights</a>.</span>
      </span>
      </p>

  </div>
    <p className="article-history cv-paragraph">
      Last updated on September 20th, 2020.
    </p>
  </section>
  </Layout>
)

export default CV;
